.dashboard-single-unit {
    text-align: center;
    background-color: #6FCF97;
    color: #FFFFFF;
}

.dashboard-list-element-header {
    width: 300px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.dashboard-list-element-title {
    font-weight: bold;
    font-variant: small-caps;
}

.dashboard-list-element-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-left: 25px;
    color: #FFFFFF;
}

.dashboard-list-element {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: 25px;
}

.dashboard-extend-width {
    width: 200px !important;
}

.dashboard-column-view {
    display: flex;
    flex-direction: column;
}

.dashboard-row-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dashboard-dropdown {

}

.dashboard-dropdown-toggle {
    background-color: #6FCF97;
    width: 100%;
}

.dashboard-info-icon-wrapper{
    display: flex;
    flex-direction: row;
}

.dashboard-info-icon {
    width: 13px;
    height: 13px;
    cursor: pointer;
    margin-top: -3px;
    margin-left: 3px;
}

.dashboard-shift-outer-left {
    margin-left: -10px;
}

.dashboard-shift-inner-left {
    margin-left: -30px;
}

.dashboard-padding-bottom {
    padding-bottom: 20px;
}

.dashboard-connected-equipment {
    float: left;
    margin-left: 30px;
    margin-bottom: 25px;
}

.dashboard-fracshack-equipment-label {
    display: inline;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #A4A4A4;
}

.dashboard-fracshack-equipment-container {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
}

.dashboard-equipment-container {
    margin-left: 30px;
}

.dashboard-fracshack-equipment-container li:after {
    content: ", ";
}

.dashboard-fracshack-equipment-container li:last-child:after {
    content: "";
}

.dashboard-non-bifuel-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    /*border: solid #CF9100;*/
}

.dashboard-sales-blurb {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.25em;
    font-weight: bold;
    color: #6FCF97;
    padding-bottom: 20px;
}

.dashboard-non-bifuel-info-container {
}

.dashboard-consumed-element {
    margin-left: 60px;
}

.dashboard-multi-title {
    color: var(--sandtinel-red);
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    font-variant: small-caps;
    text-transform: uppercase;
    margin-top: 15px;
    margin-right: 20px;
}

.dashboard-multi-secondary-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #A4A4A4;
    margin-top: 0px;
    align-self: flex-end;
}

.dashboard-multi-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
}

.dashboard-multi-secondary-value-container {
    margin-left: auto;
}

.dashboard-multi-secondary-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    margin-left: 15px;
    color: #CBCBCB;
}

.dashboard-multi-value-small {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.dashboard-multi-secondary-value-small {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #CBCBCB;
}

.dashboard-currency-label {
    text-align: center;
    color: #FFFFFF;
    background-color: #6FCF97;
    height: 31px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.confirmation-popup-text, .confirmation-popup-note{
    color: var(--sandtinel-red)
}

/* MEDIA QUERIES */
@media (max-width: 450px) {
    .dashboard-shift-inner-left {
        margin-left: 0;
    }

    .dashboard-equipment-container {
        margin-left: 0;
    }

    .dashboard-consumed-element {
        margin-left: 0;
    }

    .dashboard-connected-equipment {
        margin-left: 0;
    }
}