.curve-title-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    width: 100%;
    white-space: nowrap
}

.curve-title {
    flex: 1 1 100px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
}

.curve-inner-container {
    display: flex;
    flex-direction: row;
    position: relative;
}

.curve-column {
    padding: 2px 5px 2px 5px;
    flex: 1 1 100px;
}

.curve-load-button {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.active-curve {
    border: solid 2px var(--sandtinel-red);
    background-color: #7D7F82 !important;
}