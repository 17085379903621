@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:400,700&Roboto+Slab:400,700&display=swap);
/*
	'input-container' and 'input-container-label' might have to be in global styles since the container and label for other inputs share the same styles
*/

/*START INPUT CONTAINER STYLES*/

.input-container {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.input-container-label {
    margin-bottom: 12px;
    letter-spacing: 0.05em;
}

/*END INPUT CONTAINER STYLES*/

#advanced-settings {
    white-space: nowrap;
    padding-right: 15px;
}

.projectHorizontalToggle {
    margin-left: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.units-of-measure-toggle {
    position: relative;
    display: inline-block;
    width: 229px;
    height: 41px;
    margin-bottom: 0px;
}

.units-of-measure-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.units-of-measure-slider {
    position: absolute;
    cursor: pointer;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #F2F2F2;
    transition: .4s;
}

.units-of-measure-slider:before {
    position: absolute;
    content: "";
    height: calc(100% - 8px);
    width: calc(50% - 8px);
    left: 4px;
    bottom: 4px;

    border-radius: 5px;
    transition: .4s;
}

.units-of-measure-toggle>.units-of-measure-slider {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

input:checked+.units-of-measure-slider:before {
    -webkit-transform: translateX(calc(229px - 100px - 16px));
    transform: translateX(calc(229px - 100px - 16px));
}

.units-of-measure-text-container {
    display: table;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.units-of-measure-text, .units-of-measure-text--active {
    pointer-events: none;
    z-index: 5;
    transition: .4s;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    text-align: center;
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    text-transform: uppercase;
}

.units-of-measure-text {
    color: #BDBDBD;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
}

.units-of-measure-text--active {
    color: #35373B;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
    background: #F2F2F2;
    border-radius: 5px;

}

.react-time-picker__wrapper
{
    background-color: #fff;
}

.react-time-picker__inputGroup__divider
{
    color: black;
}

.react-time-picker__inputGroup__hour, .react-time-picker__inputGroup__minute
{
    text-align: center !important;
}

.react-time-picker__inputGroup
{
    padding: 2px;
    min-width: 0 !important;
    min-width: initial !important;
}

.react-time-picker__inputGroup__leadingZero
{
    color: #000;
}

.button-container {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 15%;
    bottom: 20px;
    right: 15px;
}

.chart-settings-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.barChartContainer {
    position: relative;
}

.loading-circle {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	-webkit-animation: 2s linear infinite loading-circle-animation;
	        animation: 2s linear infinite loading-circle-animation;
	max-width: 300px;
}

@-webkit-keyframes loading-circle-animation {
	0% {
		-webkit-transform: rotateZ(0deg);
		        transform: rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateZ(360deg);
		        transform: rotateZ(360deg);
	}
}

@keyframes loading-circle-animation {
	0% {
		-webkit-transform: rotateZ(0deg);
		        transform: rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateZ(360deg);
		        transform: rotateZ(360deg);
	}
}

.loading-circle>circle {
	-webkit-animation: 1.4s ease-in-out infinite both circle-animation;
	        animation: 1.4s ease-in-out infinite both circle-animation;
	display: block;
	fill: transparent;
	stroke: var(--sandtinel-red);
	stroke-linecap: round;
	stroke-dasharray: 283;
	stroke-dashoffset: 280;
	stroke-width: 2px;
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
}

@-webkit-keyframes circle-animation {
	0%, 25% {
		stroke-dashoffset: 280;
		-webkit-transform: rotate(0);
		        transform: rotate(0);
	}
	50%, 75% {
		stroke-dashoffset: 75;
		-webkit-transform: rotate(45deg);
		        transform: rotate(45deg);
	}
	100% {
		stroke-dashoffset: 280;
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes circle-animation {
	0%, 25% {
		stroke-dashoffset: 280;
		-webkit-transform: rotate(0);
		        transform: rotate(0);
	}
	50%, 75% {
		stroke-dashoffset: 75;
		-webkit-transform: rotate(45deg);
		        transform: rotate(45deg);
	}
	100% {
		stroke-dashoffset: 280;
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.loading {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 175px;
}
.loading-background{
		position:fixed;
		width:100%;
		left:0;right:0;top:0;bottom:0;
		background-color: rgba(0,0,0,0.5);
		z-index:9999;
		
}
.curve-title-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 5px;
    width: 100%;
    white-space: nowrap
}

.curve-title {
    -webkit-flex: 1 1 100px;
            flex: 1 1 100px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
}

.curve-inner-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: relative;
}

.curve-column {
    padding: 2px 5px 2px 5px;
    -webkit-flex: 1 1 100px;
            flex: 1 1 100px;
}

.curve-load-button {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.active-curve {
    border: solid 2px var(--sandtinel-red);
    background-color: #7D7F82 !important;
}
.pagination-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 100%;
    padding-top: 15px;
}

.active-page {
    color: white !important;
    background-color: var(--sandtinel-red) !important;
}

.inactive-page {
    color: var(--sandtinel-red) !important;
}

.pagination-element {
    text-align: center;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: var(--sandtinel-red);
}

.pagination-element:hover {
    cursor: pointer;
    color: white;
    background-color: var(--sandtinel-red);
}
.dashboard-single-unit {
    text-align: center;
    background-color: #6FCF97;
    color: #FFFFFF;
}

.dashboard-list-element-header {
    width: 300px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.dashboard-list-element-title {
    font-weight: bold;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
}

.dashboard-list-element-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-left: 25px;
    color: #FFFFFF;
}

.dashboard-list-element {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: auto;
    margin-left: 25px;
}

.dashboard-extend-width {
    width: 200px !important;
}

.dashboard-column-view {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.dashboard-row-view {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.dashboard-dropdown {

}

.dashboard-dropdown-toggle {
    background-color: #6FCF97;
    width: 100%;
}

.dashboard-info-icon-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.dashboard-info-icon {
    width: 13px;
    height: 13px;
    cursor: pointer;
    margin-top: -3px;
    margin-left: 3px;
}

.dashboard-shift-outer-left {
    margin-left: -10px;
}

.dashboard-shift-inner-left {
    margin-left: -30px;
}

.dashboard-padding-bottom {
    padding-bottom: 20px;
}

.dashboard-connected-equipment {
    float: left;
    margin-left: 30px;
    margin-bottom: 25px;
}

.dashboard-fracshack-equipment-label {
    display: inline;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #A4A4A4;
}

.dashboard-fracshack-equipment-container {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
}

.dashboard-equipment-container {
    margin-left: 30px;
}

.dashboard-fracshack-equipment-container li:after {
    content: ", ";
}

.dashboard-fracshack-equipment-container li:last-child:after {
    content: "";
}

.dashboard-non-bifuel-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 300px;
    /*border: solid #CF9100;*/
}

.dashboard-sales-blurb {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.25em;
    font-weight: bold;
    color: #6FCF97;
    padding-bottom: 20px;
}

.dashboard-non-bifuel-info-container {
}

.dashboard-consumed-element {
    margin-left: 60px;
}

.dashboard-multi-title {
    color: var(--sandtinel-red);
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    margin-top: 15px;
    margin-right: 20px;
}

.dashboard-multi-secondary-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #A4A4A4;
    margin-top: 0px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
}

.dashboard-multi-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
}

.dashboard-multi-secondary-value-container {
    margin-left: auto;
}

.dashboard-multi-secondary-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    margin-left: 15px;
    color: #CBCBCB;
}

.dashboard-multi-value-small {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.dashboard-multi-secondary-value-small {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #CBCBCB;
}

.dashboard-currency-label {
    text-align: center;
    color: #FFFFFF;
    background-color: #6FCF97;
    height: 31px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.confirmation-popup-text, .confirmation-popup-note{
    color: var(--sandtinel-red)
}

/* MEDIA QUERIES */
@media (max-width: 450px) {
    .dashboard-shift-inner-left {
        margin-left: 0;
    }

    .dashboard-equipment-container {
        margin-left: 0;
    }

    .dashboard-consumed-element {
        margin-left: 0;
    }

    .dashboard-connected-equipment {
        margin-left: 0;
    }
}
.formik-cell {
    height: 100px;
    width: 100%;
}

.formik-cell > * {
    width: 100%;
}

.formik-cell > .projectInfoValue > input, select {
    width: 100%;
}

.formik-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

.formik-title {
    font-family: Roboto Condensed;
    font-style: normal;
    text-decoration: underline;
    font-weight: bolder;
    font-size: 18px;
    color: var(--sandtinel-red);
}

.formik-controls {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}

.formik-controls > * {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(50% - 10px);
            flex-basis: calc(50% - 10px);
}
:root {
  --sandtinel-red: #8e1b30;
  --sandtinel-grey: #333333;
  --dark-grey-bg: #4d4d4d;
}


html {
  background-color: #1F2227;
}

body {
  background-color: #1F2227;
  font-family: 'Roboto', serif;
  color: #8e1b30;
  font-size: 14px;
}

a {
  color: #CF9100;
}

a:visited, a:hover, a:active {
  color: #CF9100;
  text-decoration: none;
}

.borderLeftTopRadius {
  border-top-left-radius: 10px;
}

.headerStat {
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  min-width: 200px;
}

.headerStat .title {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Calibri', 'Arial';
  line-height: 20px;
  color: #FFBB00;
  margin-bottom: 5px;
}

.headerStat .stat {
  font-family: 'Roboto Condensed';
  font-size: 35px;
  color: #FFF;
  font-weight: 700;
  line-height: 41px;
}

.headerStat .smallStat {
  font-size: 18px;
  line-height: 21px;
  font-family: 'Roboto Condensed';
  color: #fff;
}

.siteTitle {
  min-width: 200px;
  line-height: 45px;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #e1e1e1;
}

.siteTitleActive {
  padding-left: 20px !important;
}

.headerRow {
  background-color: #1F2227;
  width: 100%;
  margin-top: 5px;
}

#root {
  background-color: #1F2227;
}

.container-fluid {
  padding: 0 !important;
  clear: both;
}

.ouRow {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 10px 0px 10px 0;
  background-color: #000;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.46);
  width: 100%;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.logoContainer {
  margin-top: 10%;
  margin-bottom: 5%;
  width: 100%;
}

.logo {
  width: 100%;
}

.centerLinkContainer {
  border: 1px solid #CF9100;
  border-radius: 10px;
  padding: 5px;
  float: left;
  clear: both;
  position: relative;
  margin: 0 auto;
  width: 200px;
  margin-bottom: 50px;
  font-size: 1.2em;
}

.centerLinkContainer:hover {
  background-color: #CF9100;
  color: #000;
}

.centerLink {
  color: #CF9100;
  text-align: center;
  margin: 0 auto;
}

.centerLinks {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.ouTitleBlock {
  max-width: 200px;
  background-color: #ddd;
  padding: 10px 25px 10px 25px;
}

.ouTitle {
  font-size: 1.5em;
  line-height: 1.2em;
}

.ouStage {
  font-size: 1em;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.updatedStat {
  float: right;
  margin: 20px 30px 20px 30px;
  width: 100px;
  text-align: center;
  font-size: 0.9em;
}

.toggleButton {
  max-width: 200px;
  background-color: #ddd;
  padding: 10px 25px 10px 25px;
  cursor: pointer;
}

.wellTitle {
  font-family: 'Roboto Condensed';
  font-size: 20px;
  line-height: 30px;
  color: #9f9f9f;
  padding-left: 55px !important;
}

.wellTitleActive {
  padding-left: 40px !important;
}

.wellStatTitle {
  margin-bottom: 50px;
  margin-top: 30px;
  max-width: 100px;
}

.wellStatContainer {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -20px;
}

.wellStat {
  margin-top: 10px;
  margin-bottom: 60px
}

.wellStage {
  float: left;
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  font-family: 'Calibri', 'Arial';
  font-size: 16px;
  line-height: 20px;
  color: #FFBB00;
}

.wellStageUpdated {
  font-family: 'Roboto Condensed';
  font-size: 13px;
  line-height: 15px;
  color: #FFF;
  margin-left: 20px;
  display: none;
}

.companyHeader {
  font-size: 1.5em;
  font-weight: 700;
}

.companyLink {
  clear: both;
  float: left;
  width: 100%;
  cursor: pointer;
  color: #CF9100;
}

.fullWidth {
  width: 100%;
}

.wellLabel {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Calibri', 'Arial';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
}

.wellSmallLabel {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #D4D4D4;
}

.wellValue {
  color: #fff;
  font-size: 3em;
  margin-top: 25px;
}

.menuItem {
  cursor: pointer;
  font-family: 'Roboto Condensed';
  font-size: 18px;
  margin-right: 0px;
  padding-left: 20px;
  color: #E1E1E1;
  background-color: #333333;
  background-color: var(--sandtinel-grey);
  font-weight: 700;
  border-left: 15px solid #8e1b30;
  border-left: 15px solid var(--sandtinel-red);
}

.subMenuItem{
  cursor: pointer;
  font-family: 'Roboto Condensed';
  font-size: 14px;
  margin-right: 0px;
  padding-left: 20px;
  color: #9f9f9f
}

.menuItemActive {
  cursor: pointer;
  background-color: #333333;
  background-color: var(--sandtinel-grey);
  font-weight: 700;
  color: #8e1b30;
  color: var(--sandtinel-red);
  border-left: 15px solid #8e1b30;
  border-left: 15px solid var(--sandtinel-red);
}

.subMenuItemActive {
  cursor: pointer;
  font-weight: 700;
  color: #8e1b30 !important;
  color: var(--sandtinel-red) !important;
  margin-left: 15px;
}

.headerProjectName {
  font-family: 'Roboto Condensed';
  font-size: 55px;
  color: #5C5E63;
  line-height: 64px;
  text-transform: uppercase;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  float: left;
  margin-right: 50px;
  max-width: 35%;
}

.headerLabelContainer {
  float: left;
  margin-top: 30px;
  min-width: 200px;
  margin-right: 40px;
}

.headerLabelLabel {
  font-family: 'Calibri', 'Arial';
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #CF9100;
  text-transform: uppercase;
}

.headerLabelValue {
  font-family: 'Roboto Condensed';
  font-size: 20px;
  line-height: 23px;
  color: #545454;
}

.profileContainer {
  float: right;
  cursor: pointer;
  position: relative;
}

.smallLogoText {
  font-family: 'Roboto Slab';
  font-size: 10px;
  text-align: center;
  width: 103px;
  letter-spacing: 0.05em;
  color: #000;
  text-transform: uppercase;
  margin-top: 5px;
  line-height: 100%;
}

.smallLogo {
  width: 103px;
}

.mainBackground {
  background: linear-gradient(180deg, rgba(104, 106, 113, 0.6) 0%, rgba(34, 35, 41, 0.6) 45.75%), #1C2225;
}

.statsBackground {
  background-color: #333333;
  background-color: var(--sandtinel-grey);
  margin-top: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 100px;
  padding-top: 20px;
}

.noRightPadding {
  padding-right: 0px !important;
}

.noLeftPadding {
  padding-left: 0px !important;
}



.wellStage {
  float: left;
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  font-family: 'Calibri', 'Arial';
  font-size: 16px;
  line-height: 20px;
  color: #FFBB00;
}

.wellStageUpdated {
  font-family: 'Roboto Condensed';
  font-size: 13px;
  line-height: 15px;
  color: #FFF;
  margin-left: 20px;
  display: none;
}

.companyHeader {
  font-size: 1.5em;
  font-weight: 700;
}

.companyLink {
  clear: both;
  float: left;
  width: 100%;
  cursor: pointer;
  color: #CF9100;
}

.fullWidth {
  width: 100%;
}

.wellLabel {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Calibri', 'Arial';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
}

.wellSmallLabel {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #D4D4D4;
}

.wellValue {
  color: #fff;
  font-size: 3em;
  margin-top: 25px;
}

.couple{
  -webkit-flex: 1 0 30%;
          flex: 1 0 30%;
  margin: 5px;
  height: 100px;
  padding: 10px;
}
.headerProjectName {
  font-family: 'Roboto Condensed';
  font-size: 55px;
  color: #5C5E63;
  line-height: 64px;
  text-transform: uppercase;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  float: left;
  margin-right: 50px;
  max-width: 35%;
}

.headerLabelContainer {
  float: left;
  margin-top: 30px;
  min-width: 200px;
  margin-right: 40px;
}

.headerLabelLabel {
  font-family: 'Calibri', 'Arial';
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #CF9100;
  text-transform: uppercase;
}

.headerLabelValue {
  font-family: 'Roboto Condensed';
  font-size: 20px;
  line-height: 23px;
  color: #545454;
}

.profileContainer {
  float: right;
  cursor: pointer;
  position: relative;
}

.smallLogoText {
  font-family: 'Roboto Slab';
  font-size: 10px;
  text-align: center;
  width: 103px;
  letter-spacing: 0.05em;
  color: #000;
  text-transform: uppercase;
  margin-top: 5px;
  line-height: 100%;
}

.smallLogo {
  width: 200px;
}

.mainBackground {
  background: linear-gradient(180deg, rgba(104, 106, 113, 0.6) 0%, rgba(34, 35, 41, 0.6) 45.75%), #1C2225;
}

.noRightPadding {
  padding-right: 0px !important;
}

.noLeftPadding {
  padding-left: 0px !important;
}

.wellStatsContainer {
  background-color: #222222;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 10px 0 10px !important;
}

.logout {
  float: right;
}

.profileImageText {
  font-family: 'Calibri', 'Arial';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #585B60;
  cursor: pointer;
  text-transform: uppercase;
}

.profileHoverContainer {
  background: #E1E1E1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  left: -10px;
}

.profileLink {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #545454 !important;
}

.closeButton {
  float: right;
  right: 50px;
  position: absolute;
  cursor: pointer;
}

.flip {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.ctaContainer {
  margin-top: 30px;
}

.ctaText {
  font-family: 'Calibri', 'Arial';
  font-size: 14px;
  line-height: 17px;
  color: #D4D4D4 !important;
  float: left;
  width: 75%;
}

.infoImage {
  float: left;
  margin-right: 5px;
}

@media (min-width: 993px) {
  .headerProjectSeparator {
    display: none;
  }
  .headerSiteInfo {
    display: none;
  }
  .headerLabelsContainerMobile {
    display: none;
  }
  .siteTitleMobile {
    display: none;
  }
}

@media (max-width: 992px) {
  .headerProjectName {
    float: none;
    margin: 0 auto !important;
    text-align: center;
    font-size: 45px;
    line-height: 50px;
  }
  .subMenuItem
  {
    font-size: 20px !important;
    padding-left: 30px !important;
  }
  .subMenuItemActive{
    margin-left: 0px !important;
  }
  .profileImageText {
    display: none;
  }
  .siteInfoToggle {
    font-family: 'Calibri', 'Arial';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #CF9100;
  }
  .headerLabelsContainer {
    display: none;
  }
  .headerProjectSeparator {
    width: 90%;
    border: 0.5px solid #939598;
  }
  .headerSiteInfo {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  .headerLabelContainer {
    float: none;
    margin-right: 0px
  }
  .projectMenu {
    display: none;
  }
  .statsBackground {
    margin-top: 0px;
    padding-top: 0px;
  }
  .siteTitleMobile {
    color: #fff;
    font-family: 'Roboto Condensed';
    font-weight: bold;
    line-height: 23px;
    font-size: 23px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .wellListMobileContainer {
    background-color: #fff;
    z-index: 10000;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
  }
  .entireSiteMobile {
    font-weight: bold;
    color: #545454;
    line-height: 23px;
    font-size: 20px;
    font-family: 'Roboto Condensed';
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
    float: left;
    width: 100%;
  }
  .wellTitle {
    color: #545454;
  }
  .mainBackground {
    background: linear-gradient(180deg, rgba(104, 106, 113, 0.6) 0%, rgba(34, 35, 41, 0.6) 45.75%), #1C2225;
  }
  .statsBackground {
    background-color: transparent;
    background-color: initial;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px;
  }
  .siteTitleMobile {
    background-color: #5C5F63;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0px;
  }

  .ctaContainer {
    margin-top: 5px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
  }
  .wellStatContainer {
    padding-left: 5px;
  }
}

@media (max-width: 700px) {
  .headerProjectName {
    font-size: 35px;
    line-height: 40px;
    padding-top: 80px;
    max-width: 100%;
  }
  .wellValue {
    text-align: center;
  }
  .headerStat {
    min-width: 50px;
    margin-right: 20px
  }
  .headerStat .title {
    font-size: 16px;
    line-height: 18px;
  }
  .headerStat .stat {
    font-size: 21px;
    line-height: 24px;
  }
  .headerStat .smallStat {
    font-size: 18px;
    line-height: 20px;
  }
}

@media (max-width: 450px) {
  .headerProjectName {
    font-size: 22px;
    line-height: 25px;
    padding-top: 80px;
    max-width: 100%;
  }
  .headerStat {
    min-width: 50px;
    margin-right: 20px
  }
  .headerStat .title {
    font-size: 10px;
    line-height: 12px;
  }
  .headerStat .stat {
    font-size: 15px;
    line-height: 18px;
  }
  .headerStat .smallStat {
    font-size: 12px;
    line-height: 14px;
  }
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.errorImage {
  width: 150px;
}

.errorContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 125px;
  padding-top: 25px;
  margin-top: -175px;
  margin-left: -175px;
  width: 300px;
}

.noDataContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #899899;
}


.barChart {
  height: 300px;
}



.chartSwitch {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #899899;
  text-transform: uppercase;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.chartSwitchActive {
  color: #fff;
}

.projectInfoContainer {
  margin-top: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.projectHeaderChart {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.projectInfoTitle {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
  margin-bottom: 30px;
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
}

.projectInfoData {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.projectInfoDataLabel
{ 
  padding: 3px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}
.projectInfoDataUnit {
  float: left;
  margin-right: 25px;
}

.projectInfoDataUnitArrow {
  float: left;
  margin-right: 0px;
}

.projectInfoArrow {
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  margin-right: 0px;
  font-family: 'Roboto Condensed';
}

.projectInfoSmallData {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
}

.operatingUnitContainer {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.lineDuration {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #9B9B9B;
  margin-bottom: 10px;
}

.lineName {
  margin-top: 10px;
}

.unitStatInfoContainer {
  margin-right: 20px;
  float: left;
}

.unitStatInfoTitle {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 5px;
  margin-left: 5px;
}

.unitStatInfo {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  min-height: 50px;
}

.unitStatInfoValue {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #585858;
}

.unitStatInfoSmallValue {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #6FCF97;
  margin-top: 5px;
  display: none;
}

.unitStatInfoSubRate {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 41px;
  line-height: 48px;
  color: #585858;
}


.unitTableContainer {
  background-color: #fff;
  border-radius: 8px;
  padding-top: 30px;
  margin-top: 30px;
  overflow: auto;
}

.unitTable {
  display: inline-block;
  text-align: left;
}

.wellCell {
  min-width: 150px;
  max-width: 250px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  padding-left: 20px;
}

.wellCellHeader {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #000;
  min-width: 125px;
  padding-left: 10px;
}

.wellCellValue {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #000;
  padding-left: 10px;
}

.user-table-heading {
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
}
.user-table-heading h4{
  color:#CDCDCD;
  font-size: 16px;
  display: block;
  font-weight: lighter;
}
.breadcrumb-wrapper {
  background-color: white;
}

.breadcrumb-list {
  list-style: none;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  background-color: white;
  text-transform: uppercase;
  font-size: 24px;
}

.custom-breadcrumb-item, .custom-breadcrumb-item a {
  color: #545454;
  text-decoration: none;
}

.custom-breadcrumb-item:hover, .custom-breadcrumb-item a:hover {
  color: #404040;
  text-decoration: none;
}

.logout {
  float: right;
}

.loading-body {
  overflow: hidden;
}

.profileImageText {
  font-family: 'Calibri', 'Arial';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #585B60;
  cursor: pointer;
  text-transform: uppercase;
}

.profileHoverContainer {
  background: #E1E1E1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  left: -10px;
}

.profileLink {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #545454 !important;
}

.closeButton {
  float: right;
  right: 50px;
  position: absolute;
  cursor: pointer;
}

.flip {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.ctaContainer {
  margin-top: 30px;
}

.ctaText {
  font-family: 'Calibri', 'Arial';
  font-size: 14px;
  line-height: 17px;
  color: #D4D4D4 !important;
  float: left;
  width: 75%;
}

.infoImage {
  float: left;
  margin-right: 5px;
}

@media (min-width: 993px) {
  .headerProjectSeparator {
    display: none;
  }
  .headerSiteInfo {
    display: none;
  }
  .headerLabelsContainerMobile {
    display: none;
  }
  .siteTitleMobile {
    display: none;
  }
}

@media (max-width: 1062px) {
  .headerProjectName {
    float: none;
    margin: 0 auto !important;
    text-align: center;
    font-size: 45px;
    line-height: 50px;
  }
  .profileImageText {
    display: none;
  }
  .siteInfoToggle {
    font-family: 'Calibri', 'Arial';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #CF9100;
  }
  .headerLabelsContainer {
    display: none;
  }
  .headerProjectSeparator {
    width: 90%;
    border: 0.5px solid #939598;
  }
  .headerSiteInfo {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  .headerLabelContainer {
    float: none;
    margin-right: 0px
  }
  .projectMenu {
    display: none;
  }
  .statsBackground {
    margin-top: 0px;
  }
  .siteTitleMobile {
    color: #fff;
    font-family: 'Roboto Condensed';
    font-weight: bold;
    line-height: 23px;
    font-size: 23px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .wellListMobileContainer {
    background-color: #fff;
    z-index: 10000;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
  }

  .entireSiteMobile {
    font-weight: bold;
    color: #545454;
    line-height: 23px;
    font-size: 20px;
    font-family: 'Roboto Condensed';
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
  }
  .wellTitle {
    color: #545454;
  }
  .mainBackground {
    background: linear-gradient(180deg, rgba(104, 106, 113, 0.6) 0%, rgba(34, 35, 41, 0.6) 45.75%), #1C2225;
  }
  .statsBackground {
    background-color: transparent;
    background-color: initial;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .siteTitleMobile {
    background-color: #5C5F63;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0px;
  }
  
  .ctaContainer {
    margin-top: 5px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
  }
  .wellStatContainer {
    padding-left: 5px;
  }
}

@media (max-width: 700px) {
  .headerProjectName {
    font-size: 35px;
    line-height: 40px;
    padding-top: 80px;
    max-width: 100%;
  }
  .wellValue {
    text-align: center;
  }
  .headerStat {
    min-width: 50px;
    margin-right: 20px
  }
  .headerStat .title {
    font-size: 16px;
    line-height: 18px;
  }
  .headerStat .stat {
    font-size: 21px;
    line-height: 24px;
  }
  .headerStat .smallStat {
    font-size: 18px;
    line-height: 20px;
  }
}

@media (max-width: 450px) {
  .headerProjectName {
    font-size: 22px;
    line-height: 25px;
    padding-top: 80px;
    max-width: 100%;
  }
  .headerStat {
    min-width: 50px;
    margin-right: 20px
  }
  .headerStat .title {
    font-size: 10px;
    line-height: 12px;
  }
  .headerStat .stat {
    font-size: 15px;
    line-height: 18px;
  }
  .headerStat .smallStat {
    font-size: 12px;
    line-height: 14px;
  }
}

.margin-top-20 {
  margin-top: 20px;
}

.errorImage {
  width: 150px;
}

.error {
  color: red;
  margin-top: 5px;
}

.no-flex {
  -webkit-flex: none !important;
          flex: none !important;
}

.full-flex {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  width: 100%;
}

.errorContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 125px;
  padding-top: 25px;
  margin-top: -175px;
  margin-left: -175px;
  width: 300px;
}

.noDataContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #899899;
}

.barChart {
  height: 300px;
}


.chartSwitch {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #899899;
  text-transform: uppercase;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.chartSwitchActive {
  color: #fff;
}

.headerStats {
  padding-bottom: 1rem;
}

.projectInfoContainer {
  margin-top: 10px;
  margin-left: 30px;
  float: left;
  clear: both;
}

.projectInfoValue
{
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #4d4d4d;
  background-color: var(--dark-grey-bg);
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
  clear: both;
}

.projectInfoDataContainer {
  border-radius: 8px;
  background-color: #333333;
  background-color: var(--sandtinel-grey);
  cursor: pointer;
  float: left;
  clear: both;
  -webkit-flex: 0 1 30%;
          flex: 0 1 30%;
}

.projectInfoData {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.projectInfoDataUnit {
  float: left;
  margin-right: 25px;
}

.projectInfoDataUnitArrow {
  float: left;
  margin-right: 0px;
}

.projectInfoArrow {
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  margin-right: 0px;
  font-family: 'Roboto Condensed';
}

.projectInfoSmallData {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
}

.operatingUnitContainer {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.unitStatInfoContainer {
  margin-right: 20px;
  float: left;
  margin-top: 20px;
}

.unitStatInfoTitle {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 5px;
  margin-left: 5px;
}

.unitStatInfo {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  min-height: 50px;
}

.unitStatInfoValue {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #585858;
}

.unitStatInfoSmallValue {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #6FCF97;
  margin-top: 5px;
}

.unitStatInfoSubRate {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 41px;
  line-height: 48px;
  color: #585858;
}

.unitTableContainer {
  background-color: #fff;
  border-radius: 8px;
  padding-top: 30px;
  margin-top: 30px;
  overflow: auto;
}

.unitTable {
  display: inline-block;
  text-align: left;
}

.wellCell {
  min-width: 150px;
  max-width: 250px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  padding-left: 20px;
}

.wellCellHeader {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #000;
  min-width: 125px;
  padding-left: 10px;
}

.wellCellValue {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #000;
  padding-left: 10px;
}

.softTotal {
  background-color: #ccc;
}

.breadcrumb-wrapper {
  background-color: white;
}

.breadcrumb-list {
  list-style: none;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  text-transform: uppercase;
  font-size: 24px;
}

.custom-breadcrumb-item, .custom-breadcrumb-item a {
  color: #545454;
  text-decoration: none;
}

.custom-breadcrumb-item:hover, .custom-breadcrumb-item a:hover {
  color: #404040;
  text-decoration: none;
}

.custom-breadcrumb-separator {
  color: #d3d3d3;
  margin: auto 6px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 5px;
  padding-right: 5px;
}

.input-dark {
  background-color: #404040;
  color: #fff;
  border: none;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  height: 50px;
}

.input-dark:focus {
  background-color: #545454;
  color: #fff;
  border: none;
}

.padding-top-md {
  padding-top: 10px;
}
.padding-top-lg {
  padding-top: 20px;
}


.totalCell {
  background-color: rgba(59, 62, 65, 0.8);
  color: #fff;
}

.custom-link {
  cursor: pointer;
  text-decoration: none !important;
  color: black !important;
}

.custom-link.dark {
  color: white !important;
}

.custom-link.nav-link {
  padding: 0!important;
}

.custom-link:hover {
  color: #4a4a4a !important;
}

.display-flex.reverse {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.exportButtonContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  float: left;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 20px;
}

.exportButtonText {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  float: left;
  line-height: 24px;
}

.exportImage {
  float: left;
  margin-right: 10px;
}

.display-flex.reverse {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.hidden { display:none;
}
.body-wrapper{
  margin-top:76px;
}
.header-wrapper{
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index:99;
}

.pumperSummary
{
  margin-top: 20px;
  margin-bottom: 20px;
}
.pumperLinesHeaderCell
{
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  white-space: nowrap
}

.pumperLinesHeaderCellSort
{
  cursor: pointer;
}

.pumperLinesHeaderName
{
  width: 200px;
  margin-right: 30px;
}

.pumperLinesHeaderValue
{
  width: 100px;
  margin-right: 30px;
}

.pumperLinesHeaderValueGas
{
  width: 125px;
  margin-right: 30px;
}

.pumperLinesHeaderValueNonFrac
{
  width: 200px;
  margin-right: 30px;
}

.pumperLinesHeaderEnergy
{
  width: 125px;
  margin-right: 30px;
}

.pumperLinesHeaderTime
{
  width: 150px;
  margin-right: 30px;
}

.pumperLinesHeaderStages
{
  width: 200px;
  margin-right: 30px;
}

.pumperLinesHeaderSubRate
{
  width: 150px;
  text-align: right;
  position: relative;
}

.pumperLinesHeaderClick
{
  width: 10px;
}

.pumperLinesHeaderFinish
{
  width: 20px;
  text-align: right;
  position: relative;
  float: left;
}

.pumperLinesHeaderStart
{
  width: 120px;
  text-align: right;
  position: relative;
  float: right;
}

.pumperLinesHeaderEnergy{
  float: left;
  margin-left: 10px;
  margin-right: 30px;
  width: 125px;
}

.pumperLine
{
  cursor: pointer;
  float: left;
  background-color: #4a4a4a;
  color: #fff;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 6px;
  width: 100%;
}

.pumperLineActive
{
  background: linear-gradient(90deg, #6FCF97 5px, #4a4a4a 5px);
}

.error-button
{
  cursor: pointer;
}

.pumperLineTotal
{
  background-color: #fff;
  color: #000;
  margin-bottom: 20px;
}

.pumperLineName1{
  margin-bottom: 5px;
  float: left;
  clear: both;
}

.pumperLineName2
{
  float: left;
  clear: both;
  color: #ccc;
}

.pumperLineName
{
  float: left;
  margin-right: 30px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 200px;
}

.pumperLineValue
{
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;
  width: 100px;
}

.pumperLineValueGas
{
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;
  width: 125px;
}


.pumperLineValueNonFrac
{
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;
  width: 150px;
}

.pumperLineEnergy
{
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;
  width: 125px;
}

.pumperLineTime
{
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;
  width: 150px;
}

.pumperLineStages
{
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;
  width: 100px;
}

.pumperLineStartTime
{
  float: right;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 120px;
  text-align: right;
}

.pumperLineFinishTime
{
  float: right;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 120px;
  text-align: right;
}

.pumperLineSubRate
{
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;
  text-align: right;
  width: 150px;
}

.pumperLineClick
{
  float: right;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 30px;
  width: 10px;
}
.btn-outline-warning{
  color:#F1AD1E;
  border-color:#F1AD1E;
}
.btn-outline-warning:hover{
  color: #1F2227;
  background-color: #F1AD1E;
  border-color: #F1AD1E;
}

.btn-warning{
  color: #212529;
  background-color: #F1AD1E;
  border-color: #F1AD1E;
}

.btn-warning:hover{
  color: #1F2227;
  background-color: #d39e00;
  border-color: #F1AD1E;
}
.user-management-row{
  color:#CDCDCD;
}

.pumperTitle
{
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
  margin-left: 20px;
  float: left;
}

.pumperChartContainer
{
  margin-top: 20px;
  margin-bottom: 20px;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
  float: left;
  margin-left: 15px;
  margin-top: 20px;
}

.floatRight
{
  float: right;
  margin-right: 20px;
  margin-top: 20px;
}

.floatRightNoMargin
{
  float: right;
}

.infoIcon
{
  width: 13px;
  cursor: pointer;
  margin-top: -10px;
}

.pumperIconInfo
{
  position: absolute;
  background-color:#000;
  color:#fff;
  border-radius: 5px;
  padding: 10px;
  z-index: 10000;
  top: -10px;
  left: 180px;
  font-size: 12px;
  width: 160px;
  text-align: left;
  display: none;
}

.pumperNameLink
{
  text-decoration: underline;
}

.mobileButton
{
  background-color: #6FCF97;
  border-radius: 20px;
  color: #FFF;
  float: left;
  margin-left: 40px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 10px;
}

.whiteBackground
{
  background-color: #fff;
  float: left;
  width: 100%;
}

.mobileText
{
  float: left;
  color: #4F4F4F;
  font-size: 27px;
  margin-top: 5px;
}

.mobileArrow{
  margin-top: 0px !important;
  margin-right: 40px !important;
  margin-bottom: 10px;
}

@media(min-width: 990px)
{
  .whiteBackground{
    display: none;
  }
}

.backLink
{
  font-size: 14px;
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
  margin-left: 22px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.backLinkAdmin
{
  font-size: 14px;
  text-decoration: unset;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
}

@media (max-width: 992px)
{
  .projectInfoDataContainer
  {
    background-color: inherit !important;
  }
}

.projectPreferences
{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-shrink: 3;
          flex-shrink: 3;
}

.closeMobileMenu
{
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-decoration: underline;
}

.dashboard
{
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}

.dashboardSingle
{
  float: left;
  margin: 25px;
  min-height: 75px;
}

.dashboardSinglePad20
{
  padding-top: 20px;
}

.dashboardSingleTitle
{
  font-family: Arial;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
}

.dashboardSingleValue
{
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 55px;
  line-height: 64px;
  letter-spacing: -0.06em;
  color: #6FCF97;
}

.dashboardMulti
{
  float: left;
  height: 60px;
  min-width: 375px;
}

.dashboardMultiTitle
{
  color: #CF9100;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  text-transform: uppercase;
  float: left;
  margin-top: 15px;
  margin-right: 20px;
  text-align: right;
  width: 150px;
}

.dashboardMultiSecondaryTitle
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #A4A4A4;
  margin-top: 0px;
}

@media (min-width: 320px) and (max-width: 439px)
{
  .dashboardMultiSecondaryTitle
  {
    margin-left: 0px;
    display: none;
  }
  .dashboardMultiSecondaryValue
  {
    margin-left: 0px;
    display: none;
  }
  .dashboardMultiSecondaryValueSmall
  {
    margin-left: 0px;
    display:none;
  }
  .dashboardMulti{
    min-width: 0;
    min-width: initial;
  }
  .dashboardMultiTitle{
    width: auto;
    width: initial;
  }
  .dashboardMultiValue
  {
    font-size: 16px !important;
  }
  .dashboardMultiContainer
  {
    padding-left: 0px !important;
  }
  .dashboardProjectInfoLabel
  {
    text-align: left !important;
    float: none !important;
    text-align: left !important;
  }
  .hideMobile
  {
    display: none;
  }

  .dashboardSingle{
    margin: 0 !important;
  }
  .connectedEquipmentLabel
  {
    text-align: left !important;
  }
  .connectedEquipment
  {
    margin-left: 0px !important;
  }
}

@media (min-width: 440px) and (max-width: 770px)
{
  .dashboardMultiSecondaryTitle
  {
    margin-left: 185px;
  }
  .dashboardMultiSecondaryValue
  {
    margin-left: 185px;
  }
  .dashboardMultiSecondaryValueSmall
  {
    margin-left: 185px;
  }
}

.dashboardMultiContainer
{
  padding: 15px;
  padding-top: 10px;
  margin-top: 3px;
  float: left;
}

.dashboardMultiValue
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF;
}

.dashboardMultiSecondaryValue
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #CBCBCB;
}

.dashboardMultiValueSmall
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.dashboardMultiSecondaryValueSmall
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #CBCBCB;
}

.dashboardTitle{
  float: left;
  margin-left: 45px;
  margin-top: 20px;
}

.dashboardTitleValue
{
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
}

.energyToggle
{
  float: right;
  margin-right: 25px;
}

.energyToggleTitle
{
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: #F8F8F8;
  margin-bottom: 5px;
  margin-left: 5px;
}

.energyToggleSelected
{
  background-color: #313131;
  cursor: pointer;
  width: 150px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  float: right;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #CDCDCD;
  border-radius: 5px;
}

.energyToggleDropdown
{
  position: absolute;
  margin-top: 20px;
  background-color: #313131;
  width: 150px;
  z-index: 100000;
}

.energyToggleItem
{
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #CDCDCD;
  cursor: pointer;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.energyToggleItem:hover
{
  color: #CF9100;
}

.energyToggleArrow
{
  float: right;
  margin-top: 5px;
}

.dashboardProjectInfoLabel
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #A4A4A4;
  text-align: right;
  float: left;
  width: 150px;
  text-align: right;
  margin-right: 30px;
}

.dashboardProjectInfoValue
{
  float: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  float: left;
}

.dashboardProjectInfo
{
  float: left;
  margin-bottom: 10px;
}

.connectedEquipment{
  float: left;
  margin-left: 30px;
  /*margin-bottom: 25px;*/
}
.connectedEquipmentTitle
{
  width: auto !important;
  width: initial !important;
}

.connectedEquipmentItem
{
  float: left;
  margin-bottom: 5px;
}

.connectedEquipmentLabel
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #A4A4A4;
  text-align: left;
  float: left;
  width: 100px;
  margin-right: 30px;
}

@media (max-width: 768px)
{
  .connectedEquipmentLabel{
    text-align: right;
    margin-right: 0px;
    padding-right: 30px;
  }
}

.connectedEquipmentValue
{
  float: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  float: left;
}

.pumperLineMobileTitle
{
  display: none;
}

.pumperLinesHeader{
  margin-bottom: 5px;
  float: left;
  width: 100%;
  white-space: nowrap
}

@media (max-width: 1500px)
{
  .pumperLinesHeader{
    display: none;
  }

  .floatRightNoMargin
  {
    float: left;
  }

  .pumperLineEnergy{
    clear: both;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .pumperLineName{
    clear: both;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .pumperLineValue{
    clear: both;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .pumperLineValueGas{
    clear: both;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .pumperLineValueNonFrac{
    clear: both;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .pumperLineSubRate
  {
    clear: both;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .pumperLineFinishTime
  {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    text-align: left;
  }
  .pumperLineStartTime
  {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    text-align: left;
  }
  .pumperLineName
  {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .pumperLineName1{
    clear: none;
    margin-right: 15px;
  }
  .pumperLineName2{
    clear: none;
  }
  .pumperLineStages
  {
    clear: both;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  .pumperLineSubRate
  {
    text-align: left;
    margin-bottom: px;
    margin-top: 5px;
  }
  .pumperLineMobileTitle{
    display: inline-block;
    float: left;
    width: 150px;
  }
}

@media (min-width: 1501px) and (max-width: 1700px)
{
  .pumperLinesHeaderValueNonFrac
  {
    width: 100px !important;
  }

  .pumperLineValueNonFrac
  {
    width: 100px !important;
  }

  .pumperLineSubRate
  {
    width: 50px !important;
  }
  .pumperLinesHeaderSubRate
  {
    width: 70px !important;
  }
}

.smallLoader
{
  width: 50px;
  margin: 50px;

}

.totalLine
{
  height: 0.5px;
  color: #5C5F63;
  background-color: #5C5F63;
  width: 100%;
}

.pressure, .gasPseudoCriticalPressure, .gasPseudoCriticalTemperature, 
.gasMolecularWeight, .sphereSize, .temperature, .gasFlowRate, .liquidFlowRate, 
.waterCutPercentage, .sandLock, .sandSize
{
  width: 35%;
}

.bifuelSetupCost
{
  width: 70px;
}

.ngHeatingValue
{
  width: 50px;
}

.dailyCutoffHour
{
  width: 30px;
}

.brakeHorsePower
{
  width: 50px;
}

.loadFactorPumping, .loadFactorIdle
{
  width: 35px;
}

.hoursPerDayWorking, .hoursPerDayIdle
{
  width: 30px;
}

.dieselCostPerGallon, .ngCostPerMCF
{
  width: 40px;
}
.blurbText
{
  font-size: 12px;
  width: 100%;
  text-align: center;
  color: #fff;
  white-space: nowrap
}

.gas-composition-modal {
  background-color: #4a4a4a;
}

#gas-composition-modal-header {
  font-weight: bold;
  font-family: 'Roboto Condensed';
  color: #FFFFFF;
}

.gas-composition-text {
  margin-right: 20px;
  font-family: 'Roboto Condensed';
}
.header {
	background-color: #fff;
	width: 100%;
	height: 76px;
	padding-left: 30px;
	-webkit-flex-wrap: unset;
	        flex-wrap: unset;
}

.smallLogoContainer {
	margin: auto 0;
}

@media (max-width: 768px) {
	.smallLogoContainer {
		display: none;
	}
}

.profileImageContainer {
	margin: auto 0px;
	-webkit-align-self: flex-end;
	        align-self: flex-end;
	text-align: center;
	cursor: pointer;
	position: relative;
	margin-left: auto;
}
.error-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	height: calc(100vh - 56px);
}

.error-content {
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.error-title {
	font-family: Roboto Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 150px;
	color: #FFFFFF;
}

@media (max-width: 768px) {
	.error-title {
		font-size: 75px;
	}
}

.error-description {
	font-family: Roboto Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
	color: #FFFFFF;
	margin-bottom: 150px;
}

@media (max-width: 768px) {
	.error-description {
		font-size: 15px;
		margin-bottom: 60px;
	}
}

.error-button {
	display: table;
	max-width: 531px;
	width: 100%;
	min-height: 69px;
	background: var(--sandtinel-red);
	border-radius: 5px;
	font-family: Roboto Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	padding-left: 60px;
	padding-right: 60px;
	color: #202227;
	transition: 250ms;
}

@media (max-width: 768px) {
	.error-button {
		max-width: 300px;
		min-height: 35px;
		font-size: 15px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.error-button:visited {
	color: #202227;
}

.error-button:hover {
	background: var(--sandtinel-grey);
	text-decoration: none;
	color: var(--sandtinel-red);
	transition: 100ms;
}

.error-button-text {
	display: table-cell;
	vertical-align: middle;
}
.timeout-component {
    background-color: #4F4F4F;
}

#warning-logo {
    color: yellow;
}
