.header {
	background-color: #fff;
	width: 100%;
	height: 76px;
	padding-left: 30px;
	flex-wrap: unset;
}

.smallLogoContainer {
	margin: auto 0;
}

@media (max-width: 768px) {
	.smallLogoContainer {
		display: none;
	}
}

.profileImageContainer {
	margin: auto 0px;
	align-self: flex-end;
	text-align: center;
	cursor: pointer;
	position: relative;
	margin-left: auto;
}