.pagination-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    padding-top: 15px;
}

.active-page {
    color: white !important;
    background-color: var(--sandtinel-red) !important;
}

.inactive-page {
    color: var(--sandtinel-red) !important;
}

.pagination-element {
    text-align: center;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    height: 20px;
    width: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: var(--sandtinel-red);
}

.pagination-element:hover {
    cursor: pointer;
    color: white;
    background-color: var(--sandtinel-red);
}