/*
	'input-container' and 'input-container-label' might have to be in global styles since the container and label for other inputs share the same styles
*/

/*START INPUT CONTAINER STYLES*/

.input-container {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.input-container-label {
    margin-bottom: 12px;
    letter-spacing: 0.05em;
}

/*END INPUT CONTAINER STYLES*/

#advanced-settings {
    white-space: nowrap;
    padding-right: 15px;
}

.projectHorizontalToggle {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.units-of-measure-toggle {
    position: relative;
    display: inline-block;
    width: 229px;
    height: 41px;
    margin-bottom: 0px;
}

.units-of-measure-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.units-of-measure-slider {
    position: absolute;
    cursor: pointer;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #F2F2F2;
    -webkit-transition: .4s;
    transition: .4s;
}

.units-of-measure-slider:before {
    position: absolute;
    content: "";
    height: calc(100% - 8px);
    width: calc(50% - 8px);
    left: 4px;
    bottom: 4px;

    border-radius: 5px;
    -webkit-transition: .4s;
    transition: .4s;
}

.units-of-measure-toggle>.units-of-measure-slider {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

input:checked+.units-of-measure-slider:before {
    -webkit-transform: translateX(calc(229px - 100px - 16px));
    -ms-transform: translateX(calc(229px - 100px - 16px));
    transform: translateX(calc(229px - 100px - 16px));
}

.units-of-measure-text-container {
    display: table;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.units-of-measure-text, .units-of-measure-text--active {
    pointer-events: none;
    z-index: 5;
    -webkit-transition: .4s;
    transition: .4s;
    flex-grow: 1;
    text-align: center;
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    text-transform: uppercase;
}

.units-of-measure-text {
    color: #BDBDBD;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
}

.units-of-measure-text--active {
    color: #35373B;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
    background: #F2F2F2;
    border-radius: 5px;

}

.react-time-picker__wrapper
{
    background-color: #fff;
}

.react-time-picker__inputGroup__divider
{
    color: black;
}

.react-time-picker__inputGroup__hour, .react-time-picker__inputGroup__minute
{
    text-align: center !important;
}

.react-time-picker__inputGroup
{
    padding: 2px;
    min-width: initial !important;
}

.react-time-picker__inputGroup__leadingZero
{
    color: #000;
}

.button-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 15%;
    bottom: 20px;
    right: 15px;
}

.chart-settings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.barChartContainer {
    position: relative;
}
