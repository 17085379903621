.formik-cell {
    height: 100px;
    width: 100%;
}

.formik-cell > * {
    width: 100%;
}

.formik-cell > .projectInfoValue > input, select {
    width: 100%;
}

.formik-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formik-title {
    font-family: Roboto Condensed;
    font-style: normal;
    text-decoration: underline;
    font-weight: bolder;
    font-size: 18px;
    color: var(--sandtinel-red);
}

.formik-controls {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.formik-controls > * {
    flex: 1 0 calc(50% - 10px) !important;
}